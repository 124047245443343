header {
  background-color: #fff;
  z-index: 9999;

  .col-12 {
    padding: 0;
  }
}

.breadcrumbs {
  text-transform: uppercase;
  font-weight: bold;
  a {
    text-decoration: none;
    &:hover
      {
        text-decoration: underline;
      }
  }
  @include tablet {
  //padding: 0 25px;
  }
  li {
    display: inline;
  }
  // li:last-child {
  //   display: none;
  // }
  li a {
    font-weight: 400;
    //color: #fff;
    text-decoration: none;
  }
}

.menuToggle {
  position: absolute;
  right: 20px;
  top: 20px;

  @media screen and (min-width: $desktop-min-breakpoint) {
    display: none;
  }

  i {
    color: $base-color;
    font-size: 40px;
  }
}

.sidebar {

  nav {
    // margin: 0 -15px 0 -25px;
    // height: calc(100% - 59px);
    height: 100%;

    @include tablet {
      // margin: 0 -15px 0 -15px;
    }
  }
}

nav {

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      line-height: 1;
    }
  }

  &[data-type="home"] {

    ul {
      max-height: 115px;
    }

    li {
      display: inline-block;
      position: relative;
      height: 115px;
      background-color: #fff;

      &.item-count-1 {
        width: calc(100% / 1);
      }
      &.item-count-2 {
        width: calc(100% / 2);
      }
      &.item-count-3 {
        width: calc(100% / 3);
      }
      &.item-count-4 {
        width: calc(100% / 4);
      }
      &.item-count-5 {
        width: calc(100% / 5);
      }
      &.item-count-6 {
        width: calc(100% / 6);
      }
      &.item-count-7 {
        width: calc(100% / 7);
        a {
          font-size: 15px;
        }
      }
      &.item-count-8 {
        width: calc(100% / 8);
        a {
          font-size: 15px;
        }
      }

      @include mobile {
        display: block;
        width: 100% !important;
        height: 40px;
      }

      a {
        position: absolute;
        display: block;
        width: 100%;
        text-align: center;
        line-height: 115px;
        font-size: 18px;
        text-transform: uppercase;
        background-color: #fff;
        border-right: 1px solid #d5d5d5;

        @include tablet {
          font-size: 13px;
        }

        @media screen and (min-width: 767px) and (max-width: 1024px) {
          line-height: 1.3;
          top: 50%;
          transform: translateY(-50%);
        }

        @include mobile {
          font-size: 13px;
          line-height: 40px;
        }
      }
    }
  }

  &[data-type="inline-sub"] {
    margin-top: 30px;
    margin-bottom: 30px;

    li {
      position: relative;
      display: inline-block;
      width: calc(100% / 3 - 20px);
      margin-right: 20px;
      margin-bottom: 20px;
      vertical-align: top;

      -webkit-transition: margin .2s linear,box-shadow .2s linear;
      -moz-transition: margin .2s linear,box-shadow .2s linear;
      -o-transition: margin .2s linear,box-shadow .2s linear;
      transition: margin .2s linear,box-shadow .2s linear;

      -webkit-box-shadow: 0 1px 5px rgba(0,0,0,.16), 0 1px 3px rgba(0,0,0,.26);
      box-shadow: 0 1px 5px rgba(0,0,0,.16), 0 1px 3px rgba(0,0,0,.26);

      &:hover {
        -webkit-box-shadow: 0 3px 10px rgba(0,0,0,.19), 0 4px 8.5px rgba(0,0,0,.2);
        box-shadow: 0 3px 10px rgba(0,0,0,.19), 0 4px 8.5px rgba(0,0,0,.2);
        margin-top: -3px;
        margin-bottom: 3px;

        a {
          color: $base-color;
        }
      }
    }

    a {
      display: inline-block;
      text-decoration: none;
      font-size: 1.4rem;
      font-weight: 400;
      width: 100%;
      height: 150px;
      padding: 20px 20px 20px 20px;
      background-color: #fff;
      border: 1px solid #e5e5e5;

      @include mobile {
        width: 100%;
        display: block;
        margin-bottom: 20px;
      }

      p {
        font-size: 1.6rem;
        font-weight: 600;
        margin: 0;
        min-height: 80px;
      }

      i {
        position: absolute;
        font-size: 3rem;
        top: 50%;
        transform: translateY(-50%);
        right: 7px;
        color: $bg-color-light;
      }
    }

    .progress-bar {
      display: block;
      margin-top: 2px;
      max-width: calc(100% - 20px);
      background-color: $bg-color-light;

      .inner-progress-bar {
        display: block;
        background-color: $base-color;
      }
    }
  }

  &[data-type="sub"] {
    background: #aec2cc;

    li {
      &.hide {
        display: none !important;
      }
    }

    a {
      position: relative;
      overflow: hidden;
    }

    i {
      position: absolute;
      font-size: 18px;
      top: 50%;
      margin-top: -49px;
      right: 0px;
      padding: 40px 13px;
      transition: background-color 0.3s;
      &.download-pdf {
        position: relative;
        padding: 7px;
        margin-top: 0;
        margin-right: 5px;
        vertical-align: middle
      }
      &:hover {
        background-color: $base-color;
        color: #fff;
      }

    }

    .toggled {

      i {
        transform: rotateZ(-180deg);
      }
    }

    > ul {

      a {
        border-left: solid 4px #acacac;
        border-bottom: solid 1px #a4a4a4;
        font-weight: 300;
        transition: 0.3s border-left-color;

        &:hover {
          border-left-color: $base-color;
        }
      }

      ul {
        display: none;
      }

      li {

        &.highlight {

          > a {
            border-left-color: $base-color;
          }

          > ul {
            display: block;
          }
        }

        &.white {

          ul {
            background-color: #fff;

            a {
              border-bottom: solid 1px #ccc;
            }
          }
        }

        .indent {

          a {
            padding: 9px 44px 10px 40px;
          }
        }

        .double-indent {

          a {
            padding: 9px 44px 10px 60px;
          }
        }
      }

      > li {
        background-color: $bg-color;

        a {
          display: block;
          padding: 9px 44px 10px 25px;
        }

        ul {
          background-color: $bg-color-light;
        }
      }
    }
  }

  &[data-type="main"] {
    background: #008996;
    
    // li#node-100080,li#node-100091,li#node-100028 {
    //   display: none;
    // }

    @include tablet {
      position: fixed;
      height: calc(100% - 90px);
      max-height: 0px;
      width: 100%;
      overflow-y: auto;
      top: 90px;
      left: 0;
      z-index: 9998;
      transition: 0.3s height;

      &.open {
        max-height: 1000px;
      }
    }

    ul {
      position: relative;

      li {
        position: relative;
        display: inline-block;
        vertical-align: top;

        @include tablet {
          display: block;

          .home {
            display: none;
          }
        }

        &.searchbox {
          position: absolute;
          right: 0;
          top: 0;

          @include tablet {
            position: relative;
            right: initial;
            padding: 10px;
          }

          @media screen and (min-width: #{$desktop-min-breakpoint}) and (max-width: 1224px){
            position: relative;
            margin-bottom: 10px;
          }

          form {

            input[type="text"] {
              padding: 5px 10px;
              margin-top: 7px;
              font-size: 1.3rem;

              @include tablet {
                margin-top: 0;
                width: 95%;
              }
            }
          }
        }

        .home {
          padding: 11px 7px 6px 7px;
          background-color: $base-color;
          color: #fff;
          @include transition(0.3s background-color);

          &:hover {
            color: #fff;
            background-color: $font-color-light;
          }

          i {
            font-size: 1.8rem;
          }
        }

        &:not(:first-of-type) {
          i {
            position: absolute;
            font-size: 18px;
            top: 50%;
            margin-top: -9px;
            right: 5px;

            @include tablet {
              display: none;
            }
          }
        }

        a {
          display: block;
          padding: 9px 25px 9px 15px;
          text-transform: uppercase;
          color: $font-color-white;
          transition: 0.3s all;

          &:hover {
            color: #fff;
            background-color: $accent-color;
          }

          &.is-active {
            color: $link-color;
          }
        }

        &:hover {

          & > ul {
            display: block;
          }
        }

        > ul:before {
          position: absolute;
          left: 20px;
          top: 7px;
          display: block;
          content: "";
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;

          border-bottom: 8px solid #fff;

          @include tablet {
            display: none;
          }
        }

        ul {
          display: none;
          position: absolute;
          left: 0;
          top: 42px;
          padding-top: 15px;
          width: 230px;
          z-index: 999;

          @include tablet {
            display: block;
            position: relative;
            width: 100%;
            top: 0;
            padding-top: 0;
          }

          i {
            position: absolute;
            right: 5px;
            top: 50%;
            margin-top: -12px;

            @include tablet {
              display: none;
            }
          }

          a {
            font-weight: 500;
          }

          li {
            position: relative;
            display: block;
            background-color: #008996;

            ul {
              position: absolute;
              display: none;
              top: 0;
              left: 230px;
              padding-top: 0;

              @include tablet {
                display: block;
                position: relative;
                padding-left: 25px;
                left: 0;
              }

              &:before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
// slider pdf on demand
// .pdf-downloads {
//   background-color: #FFF;
// }
@media handheld, only screen and (max-width: 767px) {
  #download-selectie {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  a.anchor {
    display: block;
    position: relative;
    top: -95px;
    visibility: hidden;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  [data-type="sub"] {
    min-height: 100% !important;
  }
  #downloadmenu { 
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    background-color: $link-color-hover;
    a {
      color: $font-color;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 0px;
      padding-right: 20px;
      &:hover {
        color: #FFF;
      }
    }
  }
	#download-selectie {
		position: fixed;
    z-index: 60001;
    top: 0px;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: #008996;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
    color: #ffffff;
    min-height: 100%;
    overflow: auto;
    transition: all 0.3s ease;
	}

	#download-selectie.in {
		right: 0px;
	}
	#download-selectie .sluiten {
		position: absolute;
		display: block;
		width: 34px;
		height: 34px;
		right: 20px;
		top: 10px;
		background-image: url(../img/sluiten_licht.png);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 20px 20px;
		background-position: center center;
		border: 1px solid #FFF;
		border-radius: 17px 17px 17px 17px;
		-moz-border-radius: 17px 17px 17px 17px;
		-webkit-border-radius: 17px 17px 17px 17px;
	}
	#download-selectie-achtergrond {
		position: fixed;
		display: none;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		background-color: rgba(30, 30, 30, 0.8);
		z-index: 60000;
	}
	#download-selectie-achtergrond.in {
		display: block;
	}
}

@media only screen and (min-width: 1025px) {
  a.anchor {
    display: block;
    position: relative;
    top: -150px;
    visibility: hidden;
  }
  #content-row {
    // height: 100%;
    // min-height: 100%;
    // display: flex;
    // flex-direction: column;
  }
  .col-3 {
    &.sidebar {
      height: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  #sidebar-nav {
    // display: flex;
		// flex-direction: column;
    // justify-content: center;
  }
  
  #downloadmenu {
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    background-color: $link-color-hover;
    a {
      border-radius: 0 !important;
      background-color: transparent !important;
      color: $font-color;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 0px;
      padding-right: 20px;
      &:hover {
        color: #FFF;
      }
    }
  }

	#download-selectie {
		position: fixed;
		z-index: 60001;
		top: 0px;
		right: -100%;
		width: 768px;
		height: 100%;
		background-color: #008996;
		padding: 20px;
		-webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
		-moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
		color: #ffffff;
		min-height: 100%;
		overflow: auto;
		transition: all 0.3s ease;
	}

	#download-selectie.in {
		right: 0px;
	}
	#download-selectie .sluiten {
		position: absolute;
		display: block;
		width: 34px;
		height: 34px;
		right: 20px;
		top: 10px;
		background-image: url(../img/sluiten_licht.png);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 20px 20px;
		background-position: center center;
		border: 1px solid #FFF;
		border-radius: 17px 17px 17px 17px;
		-moz-border-radius: 17px 17px 17px 17px;
		-webkit-border-radius: 17px 17px 17px 17px;
	}
	#download-selectie-achtergrond {
		position: fixed;
		display: none;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		background-color: rgba(30, 30, 30, 0.8);
		z-index: 60000;
	}
	#download-selectie-achtergrond.in {
		display: block;
	}
}
.subnav-container {
  ul {
    li {
      list-style-type: square;
      a {
        color: #d5d5d5;
      }
    }
  }
}
.pdf-downloads {
  h2 {
    color: #d5d5d5;
    background-color: #008996 !important;
    
  }
  ul {
    li {
      list-style-type: square;
      a {
        color: #ffffff;
      }
    }
  }
}

a {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.4rem;
  color:$font-color;
  text-decoration: none;
  @include transition(color 0.3s);

  &:hover {
    // color: $link-color-hover;
    text-decoration: underline;
  }
}
//
// .level-3-node {
//   nav {
//     &[data-type="sub"] {
//       /* Initial hide menu items that are supposedly collapsed */
//       > ul > li {
//
//
//         > ul > li {
//
//           &:not(.highlight) {
//             display: none;
//           }
//         }
//       }
//     }
//   }
// }
// .level-4-node {
//   nav {
//     &[data-type="sub"] {
//       /* Initial hide menu items that are supposedly collapsed */
//
//       > ul > li {
//
//         &:not(.highlight) {
//           display: none;
//         }
//       }
//     }
//   }
// }
