.bx-viewport {
  height: 280px !important;
}

.bx-controls {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: -32px;
  z-index: 1000;
}

.bx-pager-item {
  display: inline-block;

  a {
    font-size: 0;
    width: 15px;
    height: 15px;
    margin: 0 5px;
    background-color: #fff;
    display: inline-block;

    &.active {
      background-color: $base-color;
    }
  }
}
