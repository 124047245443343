* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  background: $bg-color-dimmed;

  transition: 0.6s background;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 3px 10px rgba(0,0,0,.19), 0 4px 8.5px rgba(0,0,0,.2);
}

main {
  margin-top: 126px;

  @include tablet {
    margin-top: 84px;
  }
}

.container {
  width: 100%;
  max-width: $container-width;
  margin: 0 auto;
  padding: 0 15px;
}

[class*="col-"] {
    float: left;
    padding: 0 $gutter-width;
}

.row {
  margin-left: -$gutter-width;
  margin-right: -$gutter-width;
}

.row::after {
    content: "";
    clear: both;
    display: block;
}

@for $i from 1 through 12 {
  .col-#{$i} { @include grid(#{$i}); }
}

@for $i from 0 through 12 {
  .col-#{$i}-offset { @include grid-offset(#{$i}); }
}

.hide-desktop {
  @media screen and (min-width: ($tablet-breakpoint + 1)) {
    display: none !important;
  }
}

@include largeDesktop {
  @for $i from 1 through 12 {
    .col-lg-#{$i} { @include grid(#{$i}); }
  }

  @for $i from 0 through 12 {
    .col-lg-#{$i}-offset { @include grid-offset(#{$i}); }
  }
}

@include tablet {
  [class*="col-"] {
    @include grid(12);
    margin-left: 0;
  }

  @for $i from 1 through 12 {
    .col-md-#{$i} { @include grid(#{$i}); }
  }

  @for $i from 0 through 12 {
    .col-md-#{$i}-offset { @include grid-offset(#{$i}); }
  }

  .hide-tablet {
    display: none !important;
  }
}

@include mobile {

  @for $i from 1 through 12 {
    .col-sm-#{$i} { @include grid(#{$i}); }
  }

  @for $i from 0 through 12 {
    .col-sm-#{$i}-offset { @include grid-offset(#{$i}); }
  }

  .hide-mobile {
    display: none !important;
  }
}
