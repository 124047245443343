$base-color: #FF6600;
$sub-color: #A6B5BE;
$accent-color: #A6B5BE;

$font-color: #4F4F4F;
$font-color-light: #dfdfdf;
$font-color-white: #FFF;
$font-color-blue-green: #008996;

$link-color: $font-color;
$link-color-hover: #FF6600;

$bg-color: #A6B5BE;
$bg-color-light: #dfdfdf;
$bg-color-dimmed: #f2f2f2;

$base-font: "Arial", "Open Sans", sans-serif;
//$base-font: "Open Sans", "Arial", sans-serif;

$base-font-size: 12px;
$base-line-height: 1.2;

$container-width: 1280px;
$gutter-width: 15px;
$large-desktop-breakpoint: 1400px;
$desktop-min-breakpoint: 1021px;
$tablet-breakpoint: 1020px;
$mobile-breakpoint: 750px;
$float-breakpoint: 650px;


/*
 * Font sizes PX to REM
 * 10px = 1rem
 * 14px = 1.4rem
 */
