.logo {
  max-width: 127px;
  margin-top: 12px;
  margin-bottom: 7px;
  margin-left: 20px;

  @include largeDesktop {
    margin-left: 0;
  }
}

header {
  .col-12 {
    position: relative;
  }
}

.header-charts {
  position: absolute;
  right: 0;
  top: 0;
  width: 330px;
  padding: 40px 0 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);

  @include mobile {
    position: relative;
    right: initial;
    top: 0;
    margin: 0 auto;
  }

  > div {
    margin: 0 auto;

    svg {

      > text {
        font-size: 17px !important;
        fill: #fff !important;
      }
    }
  }
}

.document-title {
  position: absolute;
  right: 0px;
  top: 10px;

  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: $font-color;

  @include tablet {
    right: 80px;
  }

  @include mobile {
    top: 12px;
    font-size: 14px;
  }

  @media screen and (min-width: #{$desktop-min-breakpoint}) and (max-width: 1310px){
    right: 20px;
  }

  span {
    color: $base-color;
  }
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.text-right {
  text-align: right;
}

main.home {
  background-size: cover;
  background-position: center center;

  > div {
    position: relative;
    min-height: calc(100vh - 126px - 115px);

    @include tablet {
      min-height: calc(100vh - 84px - 115px);
    }

    .overlay {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 30px;
      width: 100%;
      text-align: center;
      max-width: 450px;

      @include mobile {
        max-width: 300px;
      }

      h1 {
        max-width: 100%;
      }
    }

    h1 {
      position: relative;
      background: #fff;
      box-shadow: 0 1px 5px rgba(0,0,0,.16), 0 1px 3px rgba(0,0,0,.26);
      font-size: 42px;
      color: $font-color;
      text-transform: uppercase;
      letter-spacing: 2px;
      padding: 50px 20px 60px;
      margin: 0;

      @include mobile {
        font-size: 25px;
        letter-spacing: 1px;
        padding: 20px 20px 30px;
        width: 100% !important;
      }
    }

    h2 {
      position: relative;
      font-size: 35px;
      text-transform: uppercase;
      background: $base-color;
      color: #fff;
      box-shadow: 0 1px 5px rgba(0,0,0,.16), 0 1px 3px rgba(0,0,0,.26);
      letter-spacing: 1px;
      padding: 20px 0;
      margin: -35px 30px 0;

      @include mobile {
        font-size: 20px;
        padding: 10px 0;
        margin: -20px 30px 0;
      }
    }
  }
}

.header-image {
  position: relative;
  width: calc(100% + 30px);
  height: 200px;
  margin-left: -15px;
  margin-right: -15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 50%;

  @include mobile {
    height: 400px;
    padding-top: 20px;
  }

  .gradient {
    position: absolute;
    top: 50%;
    bottom: 0;
    width: 100%;
    background: -moz-linear-gradient(top,rgba(0,0,0,0) 0,rgba(0,0,0,.8) 100%);
    background: -webkit-linear-gradient(top,rgba(0,0,0,0) 0,rgba(0,0,0,.8) 100%);
    background: linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0)
  }
}

.dimmed {
  background-color: #333;

  main {
    opacity: 0.2
  }
}

main {
  transition: 0.3s opacity;

  h1 {
    font-size: 1.8rem;
    color: $base-color;
    padding-bottom: 20px;
    padding-top: 20px;
    // margin: 0 -15px 0 -25px;
    border-bottom: 1px solid $bg-color;

    @include tablet {
      padding-left: 25px;
      margin: 0 -15px 0 -15px;
    }
  }

  .search-results {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;

    a {
      display: block;
      text-decoration: none;
      padding: 25px 0;
      border-bottom: 1px solid $bg-color-light;

      &:hover {

        h2 {
          color: $font-color;

          i {
            color: $font-color;
          }
        }
      }
    }

    h2 {
      display: inline-block;
      font-size: 16px;
      line-height: 22px;
      vertical-align: top;
      margin-top: 0;
      transition: 0.3s color;

      i {
        display: inline-block;
        vertical-align: top;
        font-size: 22px;
        line-height: 22px;
        margin-right: 10px;
        color: $font-color-light;
        transition: 0.3s color;
      }
    }

    p {
      font-weight: 400;
      font-style: italic;
      color: $font-color-light;
      margin: 0;
    }
  }

  .content {
    padding-bottom:20px;

    h1 {
      // margin: 0 -40px 0 -40px;

      @include tablet {
        margin: 0 -25px 0 -25px;
      }
    }

    img {
      margin-bottom: 20px;
    }
  }
  @media only screen and (min-width: 1025px) {
    img {
      &.width-100 {
        width: 100%;
        height: auto;
      }
      &.width-50 {
        width: 50%;
        height: auto;
      }
    }
  }

  .container {
    background-color: #fff;
  }

  .container.home {
    background-color: transparent;
  }

  .content {
    padding-left: 40px;
    padding-right: 40px;

    @include tablet {
      padding-left: 25px;
    }
  }

  .highcharts-container {
    margin-bottom: 20px;

    @include tablet {
      width: 100% !important;
      overflow-x: auto !important;
    }
  }

  .table-wrapper {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;

    @include tablet {
      overflow-x: auto !important;
    }

    img.img-responsive {
      max-width: 9999px;
    }
  }

  .carrousel-item {

    > h2 {
      position: relative;
      padding: 15px 10px;
      margin: 0;
      color: $font-color;
      border-bottom: 1px solid #e5e5e5;
      cursor: pointer;

      i {
        position: absolute;
        right: 0;
        top: 14px;
        transition: 0.3s all;
      }

      &.open {

        i {
          transform: rotate(-180deg);
        }
      }
    }

    .content {
      display: none;
      padding: 10px;
    }

    &:last-of-type {
      margin-bottom: 40px;
    }
  }
}

footer {
  &.browse-bar {
    border-top: 5px solid $base-color;
    background-color: #fff;

    .container {
      padding: 20px 15px;
    }

    a {
      position: relative;
      display: inline-block;
      font-size: 1.8rem;
      line-height: 1.8rem;
      font-weight: 700;
      padding: 0 80px;
      color: $font-color;

      @include mobile {
        margin: 20px 0;
      }

      &.icon-prev:before {
        content: '\e314';
        left: 20px;
      }

      &.icon-next:before {
        content: '\e315';
        right: 20px;
      }

      &.icon-prev:before,
      &.icon-next:before {
        font-family: 'Material Icons';
        position: absolute;
        top: calc(50% - 23px);
        font-size: 50px;
        color: #d2d2d2;
        line-height: 50px;
      }

      span {
        margin: 0;
        font-size: 1.4rem;
        line-height: 1.4rem;
        font-weight: 400;
      }
    }
  }
}
