html {
  font-size: 62.5%;
}

body {
  font-family: $base-font;
  font-size: 1.4rem;
  color: $font-color;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  background-color: #fff;

  @include tablet {
    width: calc(100% + 65px);
  }

  &.submenu-header {
    width: auto;
    color: $font-color;
    background-color: $sub-color;
    border-bottom: 1px solid #a4a4a4;
    padding: 0;
    margin: 0;
    a {
      display: block;
      margin: 0;
      padding: 17px 0 17px 25px;
    }

    p {
      display: block;
      margin: 0;
      padding: 18px 0 18px 25px;
    }
  }
}

p, li {
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.content {
  a {
    font-weight: 400;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: $base-color;
    }
  }
}

h2 {
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: bold;
  color: $base-color;
  margin-top: 30px;
  margin-bottom: 0;

  & + p {
    margin-top: 0;
  }

  & + h3 {
    margin-top: 5px;
  }
}

h3 {
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 600;
  margin-top: 20px;
  margin-top: 20px;

  & + p {
    margin-top: 0;
  }
  &.subparagraph{
    margin-bottom: 20px;
  }
  &.T1 {
    margin: 0 !important;
    padding: 0;
    color: $font-color-blue-green;
  }
  &.header-type-A {
    font-size: 1.6rem;
    line-height: 1.2;
    color: $base-color;
  }
  &.header-type-B {
    font-size: 1.5rem;
    line-height: 1.2;
    color: $base-color;
  }
  &.header-type-C {
    font-size: 1.4rem;
    line-height: 1.2;
    color: #4f4f4f;
  }
  &.header-type-D {
    font-size: 1.4rem;
    line-height: 1.2;
    font-weight: bold;
    font-style: normal;
    color: $font-color-blue-green;
  }
}



h4 {
  font-size: 1.4rem;
  line-height: 1.2;
  color: $font-color;
  // font-weight: 400;
  // font-style: italic;
  // margin-top: 30px;
  margin-bottom: 0px;

  & + p {
    margin-top: 0;
  }
  &.header-type-B {
    font-weight: 600;
    color: $font-color;
    margin-top: 30px;
    margin-bottom: 0;
    font-size: 1.6rem;
    line-height: 1.2;
    color: $base-color;
  }

}

h5 {
  font-size: 1.8rem;
  line-height: 1.2;
  color: $base-color;
  margin-bottom: 20px;
}
#my-report-ul input, #my-report-ul label {
  cursor: pointer;
}
main {

  // ul {
  //   padding-left: 0px;
  // }

  h2 + ul,
  h3 + ul {
    margin-top: 4px;
  }
}
.footnotes {
  list-style: none;
  padding-left: 0;

  li {
    font-size: 1.2rem;
    font-style: italic;
  }
}

section {
  &.searchresults {
    h3 {
      &.searchresult{
        font-size: 1.6rem;
        color: $base-color;
        padding-bottom: 5px;
        padding-top: 5px;
        margin-top: 0;
        // border-bottom: 1px solid $bg-color;
      }
      &.searchresults{
        font-size: 1.6rem;
        color: $base-color;
        padding-bottom: 20px;
        padding-top: 20px;
        // margin: 0 -15px 0 -25px;
        // border-bottom: 1px solid $bg-color;
      }
    }
  } 
}
