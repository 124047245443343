h1 + .table-wrapper {
  margin-top: 20px;
}

table {

  h2 {
    font-size: 1.4rem;
  }
  
  ol, ul {
    margin: 0;
    padding: 0 0 0 18px;
  }

  &.standard {
    margin-bottom: 30px;

    p {
      position: relative;
      margin: 0;
    }

    sup {
      &.hint--bottom {
        color: $base-color;
        margin-left: 2px;
        cursor: pointer;
      }
    }

    .right {
      sup {
        &.hint--bottom {
          position: absolute;
          margin-left: 3px;
          top: 7px;
        }
      }
    }

    /*hvd 6-9-2021:13:48:14 check.*/
    tbody {
      tr {
        td {
          &.right {
            float: none !important;
            text-align: right;
          }
          &.center {
            float: none !important;
            text-align: center; 
          }
          &.highlight {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }
      tr:first-of-type {
        td {
          border-top: 1px solid $base-color;
        }
      }
    }
    tr.last td {
      border-bottom: 1px solid #000;
    }
    th, td {
      padding-top: 3px;
      padding-bottom: 3px;
    }

    th {
      color: #fff;
      font-weight: 600;
      font-size: 1.8rem;
      padding: 0px 10px;
      background-color: #00818d;
      &.right {
        float: none !important;
        text-align: right;
      }
    }

    th.left {
      text-align: left;
    }

    th.center {
      text-align: center;
    }

    th.right {
      text-align: right;
    }

    td {
      height: 26px;
      padding: 0px 10px;
      vertical-align: top;
    }

    /*hvd 6-9-2021:13:50:24 check.*/
    tbody tr:nth-child(even) {
      td {
        background-color: #f9f9f9;
        &.highlight {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }

    .emphasized {
      td {
        background-color: #fff !important;

        p {
          color: $base-color;
        }
      }
    }

    .subtotal {
      td {
        color: $font-color;
        font-weight: bold;
        border-top: 1px solid $font-color-light;
        background-color: #fff !important;
      }
    }

    .total {
      td {
        color: $font-color;
        font-weight: bold;
        border-top: 2px solid #000;
        background-color: #fff !important;
      }
    }
  }
  
  tr{
    &.noteRefRow {
      position:relative;
      cursor: help;
      
      td:first-child > p:after{
          content: '(i)';
          margin-left: 2px;
          vertical-align: super;
          color: #dc241f;
          font-size: 60%;
      }
      .noteRefTable {
        position: absolute;
        display: none;
        background-color: #f9f9f9;
        border: 1px solid #bbb;
        z-index: 100;
        max-width: calc(100% - 20px);
        padding: 5px 10px;
        margin: 0px 5px 0px 50px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.19), 0 4px 8.5px rgba(0, 0, 0, 0.2);
      }
      
    } 
  }
}
@media (min-width: 600px) { 
  tr{
    &.noteRefRow {
      &:hover, &.selected {
        .noteRefTable {
          display:block;
        }
        td {
          background-color: darken(#f9f9f9,10%) !important;
        }
      } 
      
    } 
  }
}
@media (max-width: 600px) { 
  tr{
    &.noteRefRow {
      .noteRefTable {
        margin-left: 0px !important;
        max-width: calc(60% - 20px) !important;
      }
      &.selected {
        .noteRefTable {
          display:block;
        }
        td {
          background-color: darken(#f9f9f9,10%) !important;
        }
      } 
    }
  }
}
