/** Public mixins **/
@mixin grid($size) {
  width: calc(((100% / 12) * #{$size})); // Old addition: - (#{$gutter-width} * 2)
}

@mixin grid-offset($size) {
  margin-left: calc(((100% / 12) * #{$size})); // Old addition: - (#{$gutter-width} * 2)
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
}

@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin call-animation($args) {
  -webkit-animation: $args;
  animation: $args;
}

/** Media Queries **/
@mixin mobile {
  @media screen and (max-width: #{$mobile-breakpoint}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: #{$tablet-breakpoint}) {
    @content;
  }
}

@mixin largeDesktop {
  @media screen and (min-width: #{$large-desktop-breakpoint}) {
    @content;
  }
}
